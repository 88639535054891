import React from 'react'
import { Helmet } from 'react-helmet'

import { Tournament, Page } from '../lib/createPages'
import { Root, Header, Reg } from '../components'
import { TournamentsContextProvider, WpContextProvider } from '../contexts'

interface RegTemplateProps {
  pageContext: { tournament: Tournament; edges: { node: Page }[] }
}

const RegTemplate: React.FC<RegTemplateProps> = ({ pageContext }) => (
  <>
    <Helmet>
      <title>{pageContext.tournament.title} - Anmälan</title>
    </Helmet>
    <WpContextProvider tournament={pageContext.tournament}>
      <Root pageContext={pageContext}>
        <TournamentsContextProvider tournamentid={pageContext.tournament.acf.tournamentid}>
          <Header pageContext={pageContext}></Header>
          <Reg />
        </TournamentsContextProvider>
      </Root>
    </WpContextProvider>
  </>
)

export default RegTemplate
